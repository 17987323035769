import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "@firebolt-dev/jsx/jsx-runtime";
import {cloneElement} from 'react';
import {useMDXComponents} from 'firebolt';
const MDXLayout = function MDXWrapper({children}) {
  const components = useMDXComponents();
  return cloneElement(children, {
    components
  });
};
function _createMdxContent(props) {
  const _components = {
    a: "a",
    code: "code",
    h1: "h1",
    h2: "h2",
    li: "li",
    p: "p",
    ul: "ul",
    ...props.components
  }, {Meta} = _components;
  if (!Meta) _missingMdxReference("Meta", true);
  return _jsxs(_Fragment, {
    children: [_jsx(Meta, {
      title: "Reference: .env",
      description: "API Reference for using environment variables with Firebolt."
    }), "\n", _jsx(_components.h1, {
      children: ".env"
    }), "\n", _jsxs(_components.p, {
      children: ["Firebolt has built-in support for environment variables using ", _jsx(_components.code, {
        children: ".env"
      }), " files."]
    }), "\n", _jsx(_components.h2, {
      children: "Private Variables"
    }), "\n", _jsxs(_components.p, {
      children: ["All code that runs on the server such as server streaming including ", _jsx(_components.a, {
        href: "/docs/ref/handler-js",
        children: "handlers"
      }), ", ", _jsx(_components.a, {
        href: "/docs/loaders",
        children: "loaders"
      }), " and ", _jsx(_components.a, {
        href: "/docs/actions",
        children: "actions"
      }), " have full access to all environment variables through the ", _jsx(_components.code, {
        children: "process.env"
      }), " global, eg ", _jsx(_components.code, {
        children: "process.env.DB_URI"
      }), "."]
    }), "\n", _jsx(_components.h2, {
      children: "Public Variables"
    }), "\n", _jsxs(_components.p, {
      children: ["To use environment variables on the client (i.e. inside routes and components) environment variables must be prefixed with ", _jsx(_components.code, {
        children: "PUBLIC_"
      }), ", eg ", _jsx(_components.code, {
        children: "process.env.PUBLIC_DSN"
      }), "."]
    }), "\n", _jsxs(_components.p, {
      children: ["You can also change this prefix in your ", _jsx(_components.a, {
        href: "/docs/ref/config",
        children: "firebolt.config.js"
      }), " file."]
    }), "\n", _jsx(_components.h2, {
      children: "Best Practice"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: ["Create a ", _jsx(_components.code, {
          children: ".env.example"
        }), " file with good development defaults, and commit this to your repository"]
      }), "\n", _jsxs(_components.li, {
        children: ["Developers copy this file to ", _jsx(_components.code, {
          children: ".env"
        }), " and configure it as they need (this file is gitignored so that it isn't committed accidentally)"]
      }), "\n"]
    }), "\n", _jsxs(_components.p, {
      children: ["For more control you can also use ", _jsx(_components.code, {
        children: ".env.development"
      }), " and ", _jsx(_components.code, {
        children: ".env.production"
      }), " files. The former is used when running ", _jsx(_components.code, {
        children: "npm run dev"
      }), " and the latter is used when running ", _jsx(_components.code, {
        children: "npm run build"
      }), " and ", _jsx(_components.code, {
        children: "npm run start"
      }), "."]
    })]
  });
}
export default function MDXContent(props = {}) {
  return _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  });
}
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
